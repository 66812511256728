import styles from './projectBox.module.scss'
import { GithubOutlined} from '@ant-design/icons';

export default function ProjectBox(props){

    const {image, title, website, github, tech, description,hasDetails, openModal} = props


    return (
    <div className={styles.projectBox}>
        <div className={styles.imgWrapper}>
            <a href={website} target="_blank" rel="noreferrer" ><img src={image} className={styles.projectBoxImg} alt={title} /></a>
        </div>

        <div className={styles.rightSide}>
            <div className={styles.title}><a href={website} target="_blank" rel="noreferrer" >{title}</a></div>
            <div className={styles.descriptionWrapper}>{description}</div>
            <div className={styles.techStyles}>{tech?.map((item, index)=> <span key={index} className={styles.techItemStyles}>{item}</span>)}</div>
            <div className={styles.linkRow}>
                <a href={github} className={styles.github} target="_blank" rel="noreferrer">
                    <GithubOutlined  />
                </a>
                <a href={website} className={styles.demo} target="_blank" rel="noreferrer">Live Demo</a>
               {hasDetails && <span className={styles.demo} onClick={()=>openModal()}>Details</span>}
            </div>
        </div>
    </div>)
}