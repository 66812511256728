import styles from './PS_Box.module.scss'
//import { GithubOutlined} from '@ant-design/icons';

export default function PS_Box(props){

    const {image, title, website,tech, description} = props

    let DownloadBtn = "Download";

    if(website === null){
        DownloadBtn = "In progress"
    }
    return (
    <div className={styles.projectBox}>
        <div className={styles.imgWrapper}>
            <a href={website === null ? null : website} target="_blank" rel="noreferrer" ><img src={image} className={styles.projectBoxImg} alt={title} /></a>
        </div>

        <div className={styles.rightSide}>
            <div className={styles.title}><a href={website === null ? null : website} target="_blank" rel="noreferrer" >{title}</a></div>
            <div className={styles.descriptionWrapper}>{description}</div>
            <div className={styles.techStyles}>{tech?.map((item,index)=> <span key={index} className={styles.techItemStyles}>{item}</span>)}</div>
            <div className={styles.linkRow}>             
                <a href={website === null ? null : website} className={styles.demo} target="_blank" rel="noreferrer">{DownloadBtn}</a>
            </div>
        </div>
    </div>)
}