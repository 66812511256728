
import styles from './ItsyBitsy.module.scss';
import appImage from "../../assets/images/websiteImg.png";
import googlePlayStoreImage from "../../assets/images/googlePlayStoreIcon.png";
import termsAndConditions from "../../assets/TermsAndConditions_ItsyBitsy.pdf";
import privacyPolicy from "../../assets/Privacy Policy_ItsyBitsy.pdf";

export default function ItsyBitsy(){
    return (
    <div id="itsybitsy2" className={styles.itsyBitsyContainer}>
        <div className={styles.headerContainer}>
        <h1 className={styles.heading}>ItsyBitsy</h1>
        </div>
        <div className={styles.contentContainer}>
        <div className={styles.appDescriptionContainer}>
        <div className={styles.description}>
            <p className={styles.descText1}>diapers. breasfeeding.</p>{'\n'}
            <p className={styles.descText1}>sleep. analytics.</p>{'\n'}
            <p className={styles.descText1}>sync data.</p>{'\n'}
            <p className={styles.descText1}>multiple children & more.</p>{'\n'}
            <p className={styles.descText2}>the only</p>{'\n'}
            <p className={styles.descText3}>baby tracker</p>{'\n'}
            <p className={styles.descText2}>you will ever need</p>{'\n'}              
        </div>
            <div className={styles.googlePlayImageContainer}>
                <a href='https://play.google.com/store/apps/details?id=com.atds.itsybitsy'>
                    <img className={styles.googlePlayImageBox}  src={googlePlayStoreImage} alt = 'itsyBitsy'></img>   
                </a>          
            </div>
        </div>
        <div className={styles.mobileImageContainer}>   
      
        <img className={styles.imageBox}  src={appImage} alt = 'itsyBitsy'></img>   
          
                          
        </div>
        </div>
        <div className={styles.privacyContainer}>
        <p className={styles.privacyText}>
        <a style={{textDecoration:'none', color:'white'}} href={privacyPolicy}> Privacy Policy |</a>
      
        <a style={{textDecoration:'none',color:'white'}} href={termsAndConditions}>Terms and Conditions</a>
            </p>{'\n'}
        </div>
       
          
    </div>)
}