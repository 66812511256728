/* eslint-disable react/jsx-pascal-case */
import HeadingTitle from '../../components/HeadingTitle'
import React from 'react';
//import ProjectBox from '../../components/ProjectBox'
import PS_Box from '../../components/PlayStoreBox/PS_Box';
import mpImage from "../../assets/images/mp-image.png"
import itsybitsyImage from "../../assets/images/itsyBitsyGif.gif"
import styles from './PS_Section.module.scss'

export default function PS_Section(){
   
      const projects = [
        {
            image:itsybitsyImage,
            title:"ItsyBitsy: Baby Tracker",
            website:'https://play.google.com/store/apps/details?id=com.atds.itsybitsy',            
            description:(<p>Developed this subscription based android application using React Native and deployed to Google Play Store. It enables real-world users to track their baby’s day to day activities such as sleep, nursing, playtime, feedings while also viewing detailed analytics on those activities. 
                Utilized React Native, AWS (Cognito, Lambda, RDS PostgreSQL) for a secure and scalable backend, emphasizing optimal performance and user data protection.</p>),        
            tech:['React Native','Redux', 'AWS', 'Cognito','Lambda','Postgres', 'Google Console','Firebase'],
            // hasDetails: true,          
        },            
        {
            image:mpImage,
            title:"Memory Power",
            website:"https://play.google.com/store/apps/details?id=com.atds.mpgame",            
            description: (<p>Memory Power Game is a visually stunning and responsive Android app available on Google Play Store with currently 100+ real production users. 
                Leveraged React Native, Redux, Firebase, Google Console IAP, and AppLovin to create a gaming app with in-app purchases and ads. 
                <br/>    
                This game is designed to test and enhance the player's memory skills. 
                          
                
                </p>) ,
            tech:['React Native','Redux', 'Node.js','Ads','IAP','Google Console','Local Storage'],
            // hasDetails: true,          
        },
        
    ]
    return (
    <div id="playStoreApps" className={styles.projectSection}>
        <HeadingTitle title="Google Play Store" />

        <div style={{marginTop: '30px', display: 'flex', flexWrap:'wrap'}}>
            {projects.map((project, index) => {
                return (<PS_Box
                    key={index} 
                    image={project.image}
                    title={project.title}
                    website={project.website}                    
                    description={project.description}
                    tech={project.tech}
                    // hasDetails={project.hasDetails}
                    // openModal={project.openModal}
                    />
                    )
            })}
        </div>
    </div>)
}