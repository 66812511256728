import styles from './NameHeader.module.scss'
import {Button} from 'antd'
//import resume from '../../assets/Atif-Tariq-Resume.pdf'


export default function HeaderName(){
    return (
    <div className={styles.headingWrapper}>
        <div className={styles.introText}>
            <div className={styles.hi}>Hi, my name is</div>
            <div className={styles.atif}>Atif Tariq</div>
            <div className={styles.frontEnd}>Software Engineer</div>
            <div><Button type="primary" href={"/contact"} onClick={(e) => {e.preventDefault(); window.location.replace("/#contact")}} style={{margin: '15px 0 0 0'}} className={styles.hireMe} target="_blank">Hire Me</Button></div>
        </div>
    </div>);
}