
import styles from './Skills.module.scss';
import HeadingTitle from '../../components/HeadingTitle'
import {CarOutlined} from '@ant-design/icons';


export default function Skills(){
    return (
    <div className={styles.skillsWrapper} id="skills">
        <HeadingTitle title="My Skills" />
        <div className={styles.skillParent}>
            <div className={styles.skillsChildOne}>
                <div className={`${styles.skillJS} ${styles.skillBasic}`}>Javascript</div>
                {/* <div className={`${styles.skillJS} ${styles.skillBasic}`}><p className={styles.skillJS}>Javascript</p></div> */}

                <div className={`${styles.skillReact} ${styles.skillBasic}`}>React</div>
                <div className={`${styles.skillHtml} ${styles.skillBasic}`} >HTML</div>
                <div className={`${styles.skillCss} ${styles.skillBasic}`} >CSS</div>
            
            </div>
            <div className={styles.skillsChildTwo}>
                <div className={`${styles.skillNode} ${styles.skillBasic}`}>Node.js</div>
                <div className={`${styles.skillMySql} ${styles.skillBasic}`}>React Native</div>
                <div className={`${styles.skillSql} ${styles.skillBasic}`} >SQL</div>
                <div className={`${styles.skillRedux} ${styles.skillBasic}`} >Redux</div>
                <div className={`${styles.skillCars} ${styles.skillBasic}`} >Cars <CarOutlined /></div>
       
            </div>
            </div>
    </div>
    );
}