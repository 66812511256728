import HeadingTitle from '../../components/HeadingTitle'
import { GithubOutlined, LinkedinOutlined} from '@ant-design/icons';
import styles from './ContactSection.module.scss';
import {Button} from 'antd'

export default function ContactSection(){
    return (
    <div id="contact" className={styles.contactSection}>
        <HeadingTitle title="Get In Touch" />
            <div style={{minHeight: '400px'}}>

                <p>atif.tariq.developer[@]gmail[.]com</p>
                <p>Antioch, California</p>
                <p><Button type="primary"  href='https://github.com/atiftariq786' className={styles.gitHubButton} target="_blank">Github <GithubOutlined  /></Button> <Button type="primary"  href='https://www.linkedin.com/in/atif-tariq-5b00b089/' className={styles.gitHubButton} target="_blank">LinkedIn <LinkedinOutlined /></Button></p>
            </div>
    </div>)
}