  import {MenuOutlined} from '@ant-design/icons';
  import { Dropdown, Space, Divider, Button, theme } from 'antd';
  import React from 'react';
  import styles from './MobileMenu.module.scss'
  
 
  const { useToken } = theme;
  const items = [
    {
      key: '1',
      label: (
        <a style={{color:'white'}} href="/skills" onClick={(e) => {e.preventDefault(); window.location.replace("/#skills")}}>Skills</a>
      ),
    },   
    {
      key: '2',
      label: (
        <a style={{color:'white'}} href="/about"  onClick={(e) => {e.preventDefault(); window.location.replace("/#about")}}>About</a>
      ),
    },   
    {
      key: '3',
      label: (
        <a style={{color:'white'}} href="/mobileApps" onClick={(e) => {e.preventDefault(); window.location.replace("/#playStoreApps")}}>Play Store Apps</a>
      ),
     
    },
    {
      key: '4',
      label: (
        <a style={{color:'white'}} href="/webprojects" onClick={(e) => {e.preventDefault(); window.location.replace("/#projects")}}>Projects</a>
      ),
      
      
    },
    {
      key: '5',
      label: (
        <a style={{color:'white'}} href="/itservices"  onClick={(e) => {e.preventDefault(); window.location.replace("/#services")}}>Services</a>
      ),
    },   
  ];

  const MobileMenu = () => {
    const { token } = useToken();
    const menuStyle = {
      boxShadow: 'none',
      width:'138px',      
      //backgroundColor: '#00A5D1',    
      backgroundColor: '#0a9dc6',  
      textAlign:'left',      
    };
    const contentStyle = {    
      backgroundColor: '#0a9dc6',  
      // backgroundColor: token.colorBgElevated,     
      borderRadius: token.borderRadiusLG,
      boxShadow: token.boxShadowSecondary,
      width:'138px',
      marginTop:50,
      marginLeft:20,
      textAlign:'center',     
    };    
    return  (
      <Dropdown      
      placement="bottomLeft"
      trigger={['click']} 
      menu={{
        items,
      }} 
      dropdownRender={(menu) => (
        <div style={contentStyle}>         
          {React.cloneElement(menu, {
            style: menuStyle,
          })}
          <Divider
            style={{
              margin: 0,              
            }}
          />
          <Space
            style={{
              width:'140px',
              padding: 8,
              textAlign:'center',
              //backgroundColor:'green',
            }}
          >
            <Button type="primary" className={styles.resumeBtn}  target="_blank" href="/contact"  onClick={(e) => {e.preventDefault(); window.location.replace("/#contact")}}>Hire Me</Button>
          </Space>
        </div>
      )}
    >
     <button style={{background:"transparent", border:"none"}} onClick={e => e.preventDefault()}>
       <Space>
         <MenuOutlined className={styles.menuButton} />
       </Space>
     </button>
    </Dropdown>

  )
  };
  export default MobileMenu;