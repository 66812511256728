import HeadingTitle from '../../components/HeadingTitle'
import {  Modal } from 'antd';
import React, { useState } from 'react';
import ProjectBox from '../../components/ProjectBox'
import calcImg from '../../assets/images/calculatorGif.gif'
import viziPlanImg from '../../assets/images/viziPlanGif.gif'
import architecture from '../../assets/images/architecture.png'
import styles from './ProjectSection.module.scss'

export default function ProjectSection(){
    const [isModalVisible, setIsViziPlanModalVisible] = useState(false);

      const handleViziPlanClose = () => {
        setIsViziPlanModalVisible(false);
      };

      const projects = [
        {
            image:viziPlanImg,
            title:"ViziPlan",
            website:"https://viziplan-fe.web.app/",
            github:"https://github.com/atiftariq786/ViziPlan",
            description:"This is a production ready fullstack application in which users can signup, create a vision board for their life, create life goals using that vision board, and share goals with other users. The app has authentication so users can create their own account, make changes, logout, and login when necessary. The custom dashboard includes analytics charts that showcase the status of the users goals and also shows public goals shared by other users of the app.",
            tech:['React', 'Redux', 'Node.js', 'MySQL','SQL', 'Sequalize', 'Passport', 'Chart.js', 'HTML/CSS', 'Express'],
            hasDetails: true,
            openModal:() => {
                setIsViziPlanModalVisible(true);
              }
        },        
        {
            image: calcImg,
            title:"MacBook Calculator",
            website:"https://atiftariq786.github.io/React-MacBook-Calculator/",
            github:"https://github.com/atiftariq786/React-MacBook-Calculator",
            description:"This app is fully responsive macbook based calculator for desktop, tablet and mobile users. I consider this a 'basic' calculator because it does not take order of operations into account during the calculation. For example, normally 3+33 is 12 but entering this equation into this calculator will give you 18 because this calculator does operations left to right only. 3+3 = 6, and 63 = 18.",
            tech:['React', 'Javascript', 'HTML/CSS', 'React-Bootstrap'],
        },
       
    ]
    return (
    <div id="projects" className={styles.projectSection}>
        <HeadingTitle title="Projects" />

        <Modal title="ViziPlan" visible={isModalVisible} onOk={handleViziPlanClose} onCancel={handleViziPlanClose} width='600px'>
            <p>This project is for learning purposes and also to showcase my abilities to recruiters. How I designed this project and the technologies used in the architecture diagram shown below.</p>
            <p><img src={architecture} alt='Architecture design' width='550px' /></p>
        </Modal>

        <div style={{marginTop: '30px', display: 'flex', flexWrap:'wrap'}}>
            {projects.map((project,index) => {
                return (<ProjectBox
                    key={index} 
                    image={project.image}
                    title={project.title}
                    website={project.website}
                    github={project.github}
                    description={project.description}
                    tech={project.tech}
                    hasDetails={project.hasDetails}
                    openModal={project.openModal}
                    />
                    )
            })}
        </div>
    </div>)
}