// import {  Tag} from 'antd';

//import {Button} from 'antd'
import styles from './AboutSection.module.scss'
import selfImg from '../../assets/images/self2.png'
import HeadingTitle from './../../components/HeadingTitle'
//import resume from '../../assets/Atif-Tariq-Resume.pdf';

export default function AboutSection(){
    
    return (
    <div id="about" className={styles.aboutSection}>
        <div className={styles.aboutContent}>
        <HeadingTitle title="About Me" />
            <div className = {styles.aboutSubContent_flex}>
                <div className={styles.selfImgWrapper} >
                    <img src={selfImg} className={styles.selfImg} alt="me"></img>
                </div>
                <div className={styles.aboutText}>

                Hi, I'm Atif Tariq! I'm a <b>Software Developer </b> with three years of hands-on experience, specializing in React, React Native, Javascript, HTML, CSS, REST, and Node.js. 

                        <p> I bring a wealth of expertise to the table, and I'm available to collaborate on your next project. Feel free to browse my portfolio and let's create something exceptional together.</p>
                        {/* <p>I've created multiple applications with Javascript, React and React Native which you can see below and on my GitHub.</p> */}
                        {/* <Button type="primary" href= {resume} className={styles.resumeBtn}  target="_blank">Resume</Button> */}
                   
                </div>
            </div>
        </div>
    </div>)
}