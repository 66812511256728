import React from 'react';
import { BackTop } from 'antd';
import {UpSquareOutlined} from '@ant-design/icons';
import styles from "./ScrollUp.module.scss";

const ScrollUp = () => (
  <div>  
    <BackTop>
      <div className= {styles.upBtn}><UpSquareOutlined></UpSquareOutlined></div>
    </BackTop>
  </div>
);
export default ScrollUp;