import { Layout  } from 'antd'
//import 'antd/dist/antd.css'

import './App.scss';
import  MenuNavbar  from './sections/Menu/MenuNavbar'
import  NameHeader  from './sections/NameHeader/NameHeader'
import  AboutSection  from './sections/AboutSection/AboutSection'
import  ProjectSection  from './sections/ProjectSection/ProjectSection'
import  ContactSection  from './sections/ContactSection/ContactSection'
import  SideMenu from './sections/SideMenu/SideMenu'

import bgImg from "./assets/images/bg.svg"
import Skills from './sections/Skills/Skills';
import MobileMenu from './sections/Menu/MobileMenu/MobileMenu';
import ScrollUp from "./components/ScrollUp";
import PlayStore from "./sections/PlayStoreSection/PS_Section";
import ServicesSection from './sections/Services/ServicesSection';
import ItsyBitsy from './sections/ItsyBitsyPage/ItsyBitsy';
import {Route, BrowserRouter} from "react-router-dom";


const {   Footer } = Layout;

const mainComponent = ()=>{
 return (    <Layout className="layoutStyle"><div >
    <img src={bgImg} alt ='' className='imageWrapper' />
  </div>  
    <MenuNavbar />
    <MobileMenu/>
    <NameHeader />
    <SideMenu />
    <Skills />
    <AboutSection />    
    <PlayStore/>
    <ProjectSection />
    <ServicesSection/>
    <ContactSection />  
    <ScrollUp/> 
     <Footer className="footerStyle">Designed and Built by Atif Tariq</Footer>  </Layout>  )
}

function App() {
  
  return (
  <BrowserRouter basename = "/">       
    <Route exact path ="/" component = {mainComponent} /> 
    <Route exact path ="/itsybitsy" component = {ItsyBitsy} />
  </BrowserRouter>
  
  );
}
export default App;
