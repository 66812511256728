import {Menu } from 'antd'
//import {Button} from 'antd'
import styles from './Menu.module.scss'
//import 'antd/dist/antd.css';

const {Item} = Menu

export default function MenuNavbar(props){
    return (
    <span className={styles.menuStyle}>
      <Menu
    itemHoverColor='rgba(0, 10, 45, 0.88)'
        theme="light" mode="horizontal"
        className={styles.menu}>   
           <Item   key="1" className={styles.menuItem} ><a href="/skills"  onClick={(e) => {e.preventDefault(); window.location.replace("/#skills")}}>Skills</a></Item >
          <Item  key="2" className={styles.menuItem} ><a href="/about"  onClick={(e) => {e.preventDefault(); window.location.replace("/#about")}}>About</a></Item >          
          <Item  key="3" className={styles.menuItem}><a href="/mobileApps" onClick={(e) => {e.preventDefault(); window.location.replace("/#playStoreApps")}}>Play Store Apps</a></Item >
          <Item  key="4" className={styles.menuItem}><a href="/webprojects" onClick={(e) => {e.preventDefault(); window.location.replace("/#projects")}}>Projects</a></Item >         
          <Item  key="5" className={styles.menuItem}><a href="/itservices" onClick={(e) => {e.preventDefault(); window.location.replace("/#services")}}>Services</a></Item >
          {/* <Item  key="6"> <button className={styles.hireMe} href="/contact" onClick={(e) => {e.preventDefault(); window.location.replace("/#contact")}}>Hire Me</button> </Item> */}
          <Item  key="6" className={styles.menuItem}>
            <button className={styles.hireMe}
              href="/contact" onClick={(e) => {e.preventDefault(); window.location.replace("/#contact")}}>Hire Me
            </button>
            
          </Item >
               
      </Menu>
    </span>
  );
}