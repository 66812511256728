// import {  Tag} from 'antd';

//import {Button} from 'antd'
import styles from './ServicesSection.module.scss'
import HeadingTitle from '../../components/HeadingTitle'
import {DashboardOutlined,MobileOutlined, CodeOutlined,ToolOutlined,FundProjectionScreenOutlined} from '@ant-design/icons';
//import resume from '../../assets/Atif-Tariq-Resume.pdf';

export default function ServicesSection(){
    
    let servicesList = [
        {
            id:10,
            title:'Quality',
            description: `High Quality Service, Responsive Technical Support, Fast Working.`,
            image:(<DashboardOutlined className={styles.servicesIcon} />)
        },
        {
            id:11,
            title:'Frontend Development',
            description: `Using Javascript, React, HTML/CSS, API Integration `,
            image: (<FundProjectionScreenOutlined className={styles.servicesIcon} />)
        },
        {
            id:12,
            title:'Backend Development',
            description: `Using Javascript, NodeJS, Express, SQL, MySQL, Postgres.`,
            image: (<CodeOutlined className={styles.servicesIcon} />)
        },
        {
            id:13,
            title:'Mobile App Development(Android & iOS)',
            description: `React Native, Javascript, Redux, Google Console.`,
            image:(<MobileOutlined  className={styles.servicesIcon} />)
        },
        {
            id:14,
            title:'Mobile App Maintenance',
            description: `React Native, Javascript, Redux`,
            image: ( <ToolOutlined className={styles.servicesIcon} />)
        },
        {
            id:15,
            title:'Web App Maintenance',
            description: `Javascript, HTML/CSS and React, APIs Integration, NodeJS, Express, SQL, MySQL.`,
            image:( <ToolOutlined className={styles.servicesIcon} />)
        },
      
    ]

    return (
    <div id="services" className={styles.clientSection}>
        <div >
        <HeadingTitle title="Servcies" />           
        </div>
        <div className={styles.servicesContainer}>
            <div className={styles.servicesContentContainer}>
                {/* ----------------------------------------------------- */}
                {servicesList.map((item,index) => {

                    return(
                        <div className={styles.box}>
                         <div className={styles.circleWrapper}>                   
                            <div className={styles.circle}>                                                                                        
                            {item.image}
                            </div>                                                       
                        </div> 
                         <div> 
                         <p className={styles.title}>{item.title}</p>  
                         <p className={styles.description}>{item.description}</p>                         
                         </div> 
                        
                        </div>
                       
                    )
                })}             
            
               {/* ----------------------------------------------------- */}
            </div>        

        </div>
    </div>)
}