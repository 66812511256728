//import HeadingTitle from '../../components/HeadingTitle'
import styles from './SideMenu.module.scss'
import { GithubOutlined, LinkedinOutlined} from '@ant-design/icons';

export default function SideMenu(){
    return (
    <div className={styles.sideMenu}>
        <div className={styles.sideMenuItem}><a href="https://github.com/atiftariq786" ><GithubOutlined  /></a></div>
        <div className={styles.sideMenuItem}><a href="https://www.linkedin.com/in/atif-tariq-5b00b089/"><LinkedinOutlined /></a></div>
        <div className={styles.sideMenuLine}></div>
        <div className={styles.sideMenuItemText}>atif.tariq.developer@gmail.com</div>
        <div className={styles.sideMenuLine} style={{height: '100px'}}></div>
    </div>)
}